import React, { useRef } from 'react';

function TrackListdash ({ musicList, currentIndex, selectSong, isPlaying, setIsPlaying }) {
  const audioRefs = useRef([]); // Массив рефов для каждого аудио

  const playOrPause = (key) => {
    const currentAudio = audioRefs.current[key];
    if (!currentAudio) return;

    if (currentIndex === key) {
      // Если тот же трек, что и сейчас, переключаем его состояние (пауза или воспроизведение)
      if (isPlaying) {
        currentAudio.pause(); // Поставить на паузу
      } else {
        currentAudio.play(); // Воспроизвести
      }
      setIsPlaying((prevState) => !prevState); // Переключаем состояние
    } else {
      // Если выбран другой трек, останавливаем текущий и начинаем новый
      if (audioRefs.current[currentIndex]) {
        const prevAudio = audioRefs.current[currentIndex];
        prevAudio.pause(); // Ставим предыдущий трек на паузу
        prevAudio.currentTime = 0; // Сбрасываем его время
      }

      selectSong(key); // Используем selectSong для установки нового трека
      setIsPlaying(true); // Включаем воспроизведение для нового трека
      currentAudio.play(); // Воспроизводим новый трек
    }
  };


  return (
    <div className="dark:text-white">
      <div> {/* Ограничение высоты и прокрутка */}
        <table>
          <tbody>
            {musicList.map((song, key) => (
              <tr
                key={key}
                className={`h-12 ${key % 2 === 0 ? 'bg-gray-800' : 'bg-gray-700'} ${
                  currentIndex === key ? 'bg-blue-600' : '' // Убираем hover эффект
                }`}
              >
                <td >
                  <div className="flex items-center space-x-6">
                    {/* Аудио элемент с уникальным рефом для каждого трека */}
                    <audio ref={(el) => (audioRefs.current[key] = el)} src={song.audio} />
                    {/* Изображение трека с круглой формой */}
                    <div className="w-8 h-8 flex-shrink-0">
                      <img
                        alt={song.name}
                        src={song.img}
                        className="w-full h-full object-cover rounded-full"
                      />
                    </div>
                    {/* Название трека */}
                    <span >
                      {song.name}
                    </span>
                  </div>
                </td>
                <td className="text-right px-8">
                  {/* Кнопка Play/Pause */}
                  <button
                    onClick={() => playOrPause(key)}
                    className="play current-btn text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 rounded-full w-8 h-8 flex items-center justify-center"
                  >
                    {currentIndex === key && isPlaying ? (
                      <i className="fas fa-pause"></i>
                    ) : (
                      <i className="fas fa-play"></i>
                    )}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export { TrackListdash };