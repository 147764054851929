import React, { useState, useRef, useEffect } from 'react';
import { TrackListdash } from './TrackListDash'; // Импортируем компонент TrackList


const Playerdash = () => {
  const [index, setIndex] = useState(0); // Индекс текущего трека
  const [currentTime, setCurrentTime] = useState('0:00'); // Текущее время трека
  const [isPlaying, setIsPlaying] = useState(false); // Флаг состояния воспроизведения
  const [isPlayable, setIsPlayable] = useState(false); // Флаг готовности трека
  const [loadingProgress, setLoadingProgress] = useState(0); // Буферизация

  const playerRef = useRef(null); // Реф аудиоплеера
  const timelineRef = useRef(null); // Реф таймлайна
  const playheadRef = useRef(null); // Реф позиции на таймлайне

  const musicList = [
    { name: 'Превратил 27$ в 800тыс$', author: 'Royalty', img: '/files/play.jpg', audio: 'https://www.bensound.com/bensound-music/bensound-slowmotion.mp3', duration: '3:26' },
    { name: '2 пиццы за 1 млрд$', author: 'Royalty', img: '/files/play.jpg', audio: 'https://www.bensound.com/bensound-music/bensound-slowmotion.mp3', duration: '3:26' },
    { name: '375 млн$ на свалке', author: 'Royalty', img: '/files/play.jpg', audio: 'https://www.bensound.com/bensound-music/bensound-slowmotion.mp3', duration: '3:26' },
    { name: '70 биткоинов в помойку', author: 'Royalty', img: '/files/play.jpg', audio: 'https://www.bensound.com/bensound-music/bensound-slowmotion.mp3', duration: '3:26' },
    { name: 'Моё первое знакомство с криптой', author: 'Royalty', img: '/files/play.jpg', audio: 'https://www.bensound.com/bensound-music/bensound-slowmotion.mp3', duration: '3:26' },
  ];

  useEffect(() => {
    const player = playerRef.current;

    const updateProgress = () => {
      if (player?.duration) {
        const playPercent = (player.currentTime / player.duration) * 100;
        setCurrentTime(formatTime(player.currentTime));
        if (playheadRef.current) {
          playheadRef.current.style.width = `${playPercent}%`;
        }
      }
    };

    const updateBuffered = () => {
      const buffered = player.buffered;
      if (buffered.length > 0) {
        const bufferedEnd = buffered.end(buffered.length - 1);
        const duration = player.duration || 0;
        setLoadingProgress((bufferedEnd / duration) * 100);
      }
    };

    const handleEnded = () => nextSong();

    const handleCanPlay = () => setIsPlayable(true);

    player?.addEventListener('timeupdate', updateProgress);
    player?.addEventListener('progress', updateBuffered);
    player?.addEventListener('ended', handleEnded);
    player?.addEventListener('canplay', handleCanPlay);

    return () => {
      player?.removeEventListener('timeupdate', updateProgress);
      player?.removeEventListener('progress', updateBuffered);
      player?.removeEventListener('ended', handleEnded);
      player?.removeEventListener('canplay', handleCanPlay);
    };
  }, [index]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60).toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  const nextSong = () => {
    setIndex((prevIndex) => (prevIndex + 1) % musicList.length);
  };

  const prevSong = () => {
    setIndex((prevIndex) => (prevIndex - 1 + musicList.length) % musicList.length);
  };

  const selectSong = (key) => {
    setIndex(key); // Меняем индекс трека
  };

  const currentSong = musicList[index];

  return (
    <div>
      {/* Плеер */}
      <div>
        <audio ref={playerRef} className="w-full">
          <source src={currentSong.audio} type="audio/mp3" />
          Your browser does not support the audio element.
        </audio>

        {/* Прогресс бар */}
        {/*<div className="h-1 bg-gray-600 rounded-full mb-4">
          <div 
            style={{ width: `${loadingProgress}%` }} 
            className="h-full bg-blue-500 rounded-full"
          ></div>
        </div>*/}
      </div>
  
      {/* Треклист */}
      <TrackListdash
        musicList={musicList}
        currentIndex={index}
        selectSong={selectSong}
        isPlaying={isPlaying}
        setIsPlaying={setIsPlaying}
      />
    </div>
  );
  
  
};

export { Playerdash };
