import React, { useState, useEffect } from "react";

const slides = [
  {
    title: "Курс: Миллион на NFT",
    description: "Пошаговый видеокурс по заработку на NFT",
    location: "Mumbai, MH",
    date: "2024",
    image: "/files/120.jpg", // Замените на URL реального изображения
  },
  {
    title: "Курс: Миллион на NFT",
    description: "Пошаговый видеокурс по заработку на NFT",
    location: "Mumbai, MH",
    date: "2024",
    image: "/files/120.jpg", // Замените на URL реального изображения
  },
  {
    title: "Umbrella Corporation",
    description:"Innovative shop focusing on technology and",
    location: "Mumbai, MH",
    date: "2024",
    image: "/files/120.jpg", // Замените на URL реального изображения
  },
];

const truncateText = (text, maxLength) =>
  text.length > maxLength ? text.substring(0, maxLength) + "..." : text;

const CommunitySlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  const handleSlideChange = (index) => setCurrentSlide(index);

  const handleNextSlide = () =>
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);

  const handlePrevSlide = () =>
    setCurrentSlide((prevSlide) => (prevSlide - 1 + slides.length) % slides.length);

  return (
    <div className="mx-auto overflow-hidden relative text-white rounded-md">
      <div
        className="flex transition-transform duration-1000 ease-in-out"
        style={{
          transform: `translateX(-${currentSlide * 100}%)`,
        }}
      >
        {slides.map((slide, index) => (
          <div key={index} className="flex-shrink-0 w-full">
            <div className="relative animate-border bg-gradient-to-br">
              <div className="flex p-2 rounded-2xl bg-gray-800">
                <img
                  src={slide.image}
                  alt={slide.title}
                  className="w-28 h-36 rounded-md object-cover flex-shrink-0"
                />
                <div className="ml-2 flex-grow">
                  <h2 className="text-lg font-semibold">
                    {truncateText(slide.title, 20)}
                  </h2>
                  <p className="text-sm text-gray-400">
                    {truncateText(slide.description, 50)}
                  </p>
                  <p className="text-sm text-gray-500">{slide.location}</p>
                  <p className="text-sm text-gray-500">{slide.date}</p>
                  <p className="text-sm text-gray-500">{slide.products}</p>
                </div>
                <button
                  className="absolute bottom-2 right-2 bg-blue-600 text-white py-1 px-3 text-xs rounded-full shadow-md hover:bg-blue-500 transition-all duration-300"
                  onClick={() => alert("Кнопка нажата")}
                >
                  Подробнее
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="absolute top-1/2 left-0 transform -translate-y-1/2 px-4 z-10">
        <button
          className="bg-gray-700 text-white py-2 px-3 rounded-full shadow-md hover:bg-gray-600 transition-all duration-300"
          onClick={handlePrevSlide}
        >
          &#10094;
        </button>
      </div>
      <div className="absolute top-1/2 right-0 transform -translate-y-1/2 px-4 z-10">
        <button
          className="bg-gray-700 text-white py-2 px-3 rounded-full shadow-md hover:bg-gray-600 transition-all duration-300"
          onClick={handleNextSlide}
        >
          &#10095;
        </button>
      </div>

      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
        {slides.map((_, index) => (
          <button
            key={index}
            onClick={() => handleSlideChange(index)}
            className={`w-3 h-3 rounded-full ${
              index === currentSlide ? "bg-gray-500" : "bg-gray-700"
            }`}
          ></button>
        ))}
      </div>
    </div>
  );
};

export default CommunitySlider;
