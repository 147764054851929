import React, { useState } from "react";

const Calendar = () => {
  const today = new Date();
  const [currentYear, setCurrentYear] = useState(today.getFullYear());
  const [currentMonth, setCurrentMonth] = useState(today.getMonth());

  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getStartDayOfMonth = (year, month) => {
    return new Date(year, month, 1).getDay(); // 0 (воскресенье) - 6 (суббота)
  };

  const daysInMonth = getDaysInMonth(currentYear, currentMonth);
  const startDay = getStartDayOfMonth(currentYear, currentMonth);

  const days = Array.from({ length: startDay }, () => "").concat(
    Array.from({ length: daysInMonth }, (_, i) => i + 1)
  );

  const handlePrevMonth = () => {
    if (currentMonth === 0) {
      setCurrentYear((prev) => prev - 1);
      setCurrentMonth(11);
    } else {
      setCurrentMonth((prev) => prev - 1);
    }
  };

  const handleNextMonth = () => {
    if (currentMonth === 11) {
      setCurrentYear((prev) => prev + 1);
      setCurrentMonth(0);
    } else {
      setCurrentMonth((prev) => prev + 1);
    }
  };

  const tooltipMessages = {
    11: { // Декабрь
      20: "Tomat",
      22: "TonStation",
    },
    0: { // Январь
      8: "CatGold",
    },
  };
  

  const getTooltipMessage = (day) => {
    return tooltipMessages[currentMonth]?.[day] || "";
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <button onClick={handlePrevMonth} style={{ cursor: "pointer" }}>←</button>
        <h2>
          {currentYear}, {new Date(currentYear, currentMonth).toLocaleString("ru-RU", { month: "long" })}
        </h2>
        <button onClick={handleNextMonth} style={{ cursor: "pointer" }}>→</button>
      </div>
      <table>
        <thead>
          <tr>
            {["В", "П", "В", "С", "Ч", "П", "С"].map((day, index) => (
              <th
                key={index}
                style={{ fontSize: "0.8rem", color: "rgba(0, 0, 0, 0.6)", textAlign: "center" }}
              >
                {day}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: Math.ceil(days.length / 7) }, (_, week) => (
            <tr key={week}>
              {days.slice(week * 7, week * 7 + 7).map((day, index) => (
                <td
                  key={index}
                  style={{
                    textAlign: "center",
                    cursor: day ? "pointer" : "default",
                  }}
                >
                  <div
                    className={
                      getTooltipMessage(day)
                        ? "tooltip underline font-bold text-red-500"
                        : ""
                    }
                    data-tip={getTooltipMessage(day)}
                  >
                    {day || ""}
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Calendar;
