import React from 'react';
import Player from '../Components/Podcast/PlayerHistory';

function Podcast() {
  return (
    <div id="refer" className="w-full h-screen pt-9 scroller space-y-3 overflow-y-auto max-w-[450px]">
      <div className="text-center text-white mb-2"><h1>Подкасты CryptoFans</h1></div>
      <Player />
    </div>
  );
}

export default Podcast;
