import React from 'react';
import { Link } from 'react-router-dom';

// Компонент Card
const Card = ({ title, price, imageSrc, description, link }) => (
  <div className="bg-white px-2 py-2 rounded-xl shadow-lg w-[150px] h-[220px] flex flex-col items-center justify-between">
    <h3 className="text-sm font-bold text-indigo-600 text-center">{title}</h3>
    <div className="relative w-full h-[120px] mt-2">
      <img
        className="w-full h-full object-cover rounded-xl"
        src={imageSrc}
        alt={description}
      />
      <p className="absolute top-0 bg-yellow-300 text-gray-800 font-semibold py-1 px-2 text-xs rounded-br-lg rounded-tl-lg">
        {price}
      </p>
    </div>
    <h1 className="text-xs text-gray-800 text-center">{description}</h1>
    <Link to={link}>
      <button className="mt-2 text-sm w-full text-white bg-black py-1 px-3 rounded-xl shadow-md hover:bg-gray-800">
        Слушать
      </button>
    </Link>
  </div>
);

// Компонент PodcastHome
const PodcastHome = () => {
  const cardsData = [
    {
      title: "Крипто истории",
      price: "Подкасты",
      imageSrc:
        "/files/cript3.jpg",
      description: "Интересно, реально",
      link: "/Podcast", // Ссылка
    },
    {
      title: "Обзор проектов",
      price: "Подкасты",
      imageSrc:
        "/files/cript2.jpg",
      description: "Аирдропы, майнинг",
      link: "/Ref", // Ссылка
    },
  ];

  return (
    <div>
      <h1 className="text-center text-3xl font-bold">Страница Подкастов</h1>
      <p className="text-center text-lg mb-4">Добро пожаловать на страницу подкастов!</p>
      <div className="w-full h-screen pt-5 scroller overflow-y-auto pb-[10px]">
        <div className="grid grid-cols-2 gap-[5px] max-w-[400px] mx-auto">
          <div className="flex flex-col gap-[5px] items-end">
            {cardsData
              .filter((_, index) => index % 2 === 0)
              .map((card, index) => (
                <Card
                  key={`card-right-${index}`}
                  title={card.title}
                  price={card.price}
                  imageSrc={card.imageSrc}
                  description={card.description}
                  link={card.link}
                />
              ))}
          </div>
          <div className="flex flex-col gap-[5px] items-start">
            {cardsData
              .filter((_, index) => index % 2 !== 0)
              .map((card, index) => (
                <Card
                  key={`card-left-${index}`}
                  title={card.title}
                  price={card.price}
                  imageSrc={card.imageSrc}
                  description={card.description}
                  link={card.link}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PodcastHome;
