import React from "react";

const Card = ({ title, price, imageSrc, description }) => (
  <div className="bg-white px-2 py-2 rounded-xl shadow-lg w-[150px] h-[220px] flex flex-col items-center justify-between">
    <h3 className="text-sm font-bold text-indigo-600 text-center">{title}</h3>
    <div className="relative w-full h-[120px] mt-2">
      <img
        className="w-full h-full object-cover rounded-xl"
        src={imageSrc}
        alt={description}
      />
      <p className="absolute top-0 bg-yellow-300 text-gray-800 font-semibold py-1 px-2 text-xs rounded-br-lg rounded-tl-lg">
        {price}
      </p>
    </div>
    <h1 className="text-xs text-gray-800 text-center">{description}</h1>
    <button className="mt-2 text-sm w-full text-white bg-black py-1 rounded-xl shadow-md hover:bg-gray-800">
      Buy Lesson
    </button>
  </div>
);

const CursPage = () => {
  const cardsData = [
    {
      title: "Beginner Friendly",
      price: "FREE",
      imageSrc:
        "https://images.unsplash.com/photo-1541701494587-cb58502866ab?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80",
      description: "JS Bootcamp",
    },
    {
      title: "Intermediate",
      price: "$12",
      imageSrc:
        "https://images.unsplash.com/photo-1550684848-fac1c5b4e853?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1050&q=80",
      description: "Gatsby Plugin",
    },
    {
      title: "Advanced React",
      price: "$50",
      imageSrc:
        "https://images.unsplash.com/photo-1561835491-ed2567d96913?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1050&q=80",
      description: "React Native",
    },
  ];

  return (
    <div>
      <h1 className="text-center text-3xl font-bold">Страница курсов</h1>
      <p className="text-center text-lg mb-4">Добро пожаловать на страницу курсов!</p>
      <div
        id="refer"
        className="w-full h-screen pt-5 scroller overflow-y-auto pb-[10px]"
      >
        {/* Grid с выравниванием колонок */}
        <div
          className="grid grid-cols-2 gap-[5px] max-w-[400px] mx-auto"
          style={{
            gridTemplateAreas: `"right left"`, // Левая колонка справа, правая колонка слева
          }}
        >
          <div
            className="flex flex-col gap-[5px] items-end"
            style={{ gridArea: "right" }} // Выравнивание правой колонки
          >
            {cardsData.map((card, index) => (
              <Card
                key={`card-right-${index}`}
                title={card.title}
                price={card.price}
                imageSrc={card.imageSrc}
                description={card.description}
              />
            ))}
          </div>
          <div
            className="flex flex-col gap-[5px] items-start"
            style={{ gridArea: "left" }} // Выравнивание левой колонки
          >
            {cardsData.map((card, index) => (
              <Card
                key={`card-left-${index}`}
                title={card.title}
                price={card.price}
                imageSrc={card.imageSrc}
                description={card.description}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CursPage;
